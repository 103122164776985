.home-chef {
  background-image: linear-gradient(rgb(177, 221, 240), rgb(103, 128, 230));
}

.home-chef-status {
  background-color: rgba(255, 234, 0, 0.505);
  padding: 1rem;
  margin-bottom: 2rem;
}

.job-name {
  display: flex;
  align-items: center;
}

.home-chef-announcement {
  background-color: rgb(252, 219, 121);
  border: 3px brown solid;
  padding: .5rem;
  font-size: 1rem;
  text-align: center;
  box-shadow: -5px 2px 10px rgba(0, 0, 0, 0.442);
  margin-bottom: 2rem;
}

.home-chef-announcement h4 {
  margin-top: 0;
  margin-bottom: 5px;
}


.expand-btn {
  margin-right: 1rem;
  background-color: white;
  min-width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all .3s;
}

.expanded {
  transform: rotate(90deg);
}

.hc-home {
  display: flex;
  align-items: flex-start;
}

@media(max-width: 500px) {
  .hc-home {
    flex-direction: column;
    /* flex-flow: column-reverse; */
    align-items: center;
  }
}

.hc-home-photo {
  max-width: 100%;
  justify-self: center;
}

.hc-home-active-status {
  font-size: 1rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  color: rgb(54, 54, 54);
}

.home-chef-total-meals {
  background-color: rgba(255, 228, 94, 0.809);
  display: inline-block;
  border: blue 1px solid;
  border-radius: 25px;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.3rem;
  text-align: center;
  box-shadow: -2px 3px 5px;
}

.home-chef-home-right-col {
  max-width: 30%;
  margin-left: 2rem;
  min-width: 200px;
}

@media (max-width: 500px) {
  .home-chef-home-right-col {
    max-width: 100%;
    margin: 0;
    margin-top: 2rem;
  }

  .home-chef-status {
    font-size: .7rem;
  }

  .home-chef-status li {
    font-size: .7rem;
  }
}

.home-chef-header {
  width: 95%;
  max-width: 500px;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: .5s all;
  border-radius: 50px;
}

.home-chef-header:hover {
  filter: hue-rotate(120deg);
  background-color: rgba(240, 248, 255, 0.287);
}

.volunteer-edit {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.edit-shift-date {
  color:rgb(77, 20, 130);
  font-size: 1rem;
  margin-left: 1rem;
}

.edit-chef-type {
  display: flex;
  margin-bottom: 1rem;
}