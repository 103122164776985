.meal-program {
  background-image: linear-gradient(rgb(251, 211, 163), rgb(206, 148, 66));
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.meal-program-title {
  color: rgb(0, 0, 147);
  width: fit-content;
  transition: all .5s;
  padding: 0 2rem;
  border-radius: 50px;
  margin-right: 3rem;
}

.meal-program-title:hover {
  color: rgb(0, 139, 18);  
  background-color: rgba(255, 255, 255, 0.477);
}

.meal-program-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.meal-program-restaurant {
  display: flex;
}

.meal-program-restaurant-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  padding: 1rem;
}

.meal-program-restaurant-name {
  font-weight: 600;
  color: rgb(48, 50, 106)
}

.meal-program-restaurant-status-inactive {
  color: rgb(99, 25, 0);
  font-weight: 600;
}

.meal-program-restaurant-status-active {
  color: rgb(23, 122, 43);
  font-weight: 600;
}

.meal-program-home {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.meal-program-home-image {
  min-width: 400px;
  max-width: 40vw;
  margin-top: 2rem;
}

.meal-program-home-text {
  margin-bottom: 2rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (max-width: 650px) {
  .meal-program-home-image {
    min-width: 100%;
  }
}

.meal-program-navigate {
  color: black
}
