.form-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 215, 156);
  padding-bottom: 5rem;
}


.form {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}

.form-item {
  display: flex;
  flex-direction: column;
  margin: .5rem;
  border: rgb(205, 205, 205) solid 1px;
  border-radius: 10px;
  padding: 2rem;
  background-color: white;
}

.form-item input[type='text'], .form-item input[type='email'], .form-item input[type='tel'] {
  border: none;
  border-bottom: 1px rgb(176, 176, 176) solid;
  width: 80%
}

.form-item input[type='text']:focus-visible, .form-item input[type='email']:focus-visible, .form-item input[type='tel']:focus-visible {
  outline: none;
  border: none;
  border-bottom: 2px rgb(109, 0, 0) solid;
  width: 80%
}


.form-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}


.form label {
  margin-bottom: 2rem;
}

.form-checkbox input {
  margin-bottom: 0;;
}

.form-item p, li {
  margin: 0;
  line-height: 1.3rem;
  font-size: .9rem;
}


.form-item h1 {
  margin-top: 0;
}

.bold {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.form-img {
  margin-bottom: 2rem;
  width:100%;
}

.form-logo {
  background-color: transparent;
  padding: .5rem 1.5rem;
  max-width: 95%;
}

.required {
  margin-left: 10px;
  color: red;
}

.required-error {
  color: red;
}

.form-horizontal {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-horizontal label {
  margin: 0;
  margin-right: 1.2rem;
}

.form-horizontal input {
  flex: 1;
}

.form-horizontal input[type="radio"] {
  margin-right: 10px;
}

.form-horizontal input[type="date"] {
flex: .4;}

.form-checkbox label {
  margin-bottom: 0;
}

.form-sent {
  height: 100vh;
}

.demo-item {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgb(204, 204, 204);
  padding-bottom: 5px;
  margin: .3rem;
}

.demo-sub-item {
  display: flex;
  width: 50%;
}


.demo-item label {
  width:50%;
  margin: 0;
}

.demo-item input {
  margin: 0;
  width: 20%
}

.demo-title {
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.form-zip-btns {
  margin-bottom: 1rem;
  margin-left: -1rem;
  display: flex;
}

.form-instructions {
  margin-bottom: 2rem;
}

.form-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-decoration: underline;
  margin-left: auto;
  margin-right: auto;
}

.form-mobile-oasis {
  background-color:lightgray;
  border-color: black;
  display: flex;
  align-items: stretch;
}

.form-center-text {
  align-self: center;
  text-align: center;
}

.form-link {
  align-self: center;
  margin-top: 3rem;
}

.form-content {
  margin-bottom: 2rem;
}

.form-language-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-language-switch-label {
  font-weight: 600;
}

/* The switch - the box around the slider */
.form-switch {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.form-switch-toggle {
  background-color: green;
  height: 30px;
  width: 60px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  transition: all .3s;
  margin-left: 1.5rem;
}

.form-switch-button {
  background-color: white;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  transition: all .3s;
}

/* Hide default HTML checkbox */
.form-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.form-switch label {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 800;
}

.form-switch-label {
  color: rgb(80,80,80)
}

.form-switch-toggled {
  background-color: blue;
}

.form-switch-toggled .form-switch-button {
  transform: translateX(30px);
}

.form-switch-selected-english {
  color:  green;
}

.form-switch-selected-spanish {
  color: blue;
}