.hc-confirm-details {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.hc-confirm-details ul {
  margin-bottom: 2rem;
  display: inline-block;
  padding: 0;
}

.hc-confirm-item {
  margin-top: 1.5rem;
  margin-left: 2rem;
  font-weight: 600;
}

.hc-confirm-title {
  font-size: 1.5rem;
  margin-right: .5rem;
}

.hc-confirm-button {
  margin-bottom: 1rem;
  color: black;
}