.admin {
  background-image: linear-gradient(rgb(79, 22, 79), rgb(0, 0, 0));
  color: white;
}

.admin-home {
  display: flex;
  flex-direction: column;
}

.admin-home-btn {
  width: fit-content;
  margin-top: 1rem;
}

.admin-item {
  border: 2px solid rgb(189, 255, 253);
  padding: 1rem;
  margin-right: 2rem;
  height: fit-content;
  width: fit-content;
}

.admin-form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.create-main {
  display: flex;
  flex-wrap: wrap;
}

.alert-box {
  height: 200px;
  color: black
}

.admin-form textarea {
  height: 200px;
  width: 300px;
  margin-bottom: 2rem;
}

.admin-form label {
  margin-top: 1rem;
}

.admin-delete-user {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px grey solid;
  padding: 1rem;
}

.admin-id-text {
  font-size: 12px;
}

.admin-app-name {
  font-size: 2rem;
  color: yellow;
  margin-left: .5rem;
  margin-right: .5rem;
}

.d4j-btn {
  margin-bottom: 2rem;
  color: black;
  font-size: 2rem;
  padding:1rem;
}

.d4j-item {
  margin-bottom: 1rem;
}
