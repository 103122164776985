.app {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

a, a:visited {
  text-decoration: none;
}

.retro-link {
  text-decoration: underline;
  cursor: pointer;
}

.error {
  background-color: red;
  padding: 1rem;
  position: fixed;
  border: solid black 2px;
  top: 1rem;
  left: 23%;
  width: 50%;
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.538);
}

.alert {
  background-color: rgb(167, 255, 167);
}

input, select {
  padding: .3rem;
}

input, select, label {
  margin-right: 1.5rem;
  margin-bottom: .7rem;
  margin-top: 0;
  height: fit-content;
}

input[type=submit], button, .button {
  background-color: rgb(98, 163, 255);
  border: 2px solid grey;
  border-radius: 50px;
  padding: .5rem 1rem;
  margin-right: .5rem;
  margin-left: .5rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1rem;
  transition: .3s;
  cursor: pointer;
  width: max-content;
  color: inherit;
}

input[type=submit] {
  margin-top: 1.5rem;
}

input[type=submit]:hover, button:hover, .nav-link-active {
  background-color: rgb(128, 193, 285);
}

.nav-button {
  margin-top: 1rem;
  color: black;
}

input[type=submit]:active, button:active {
  transform: translateY(5px);
  box-shadow: none;
}

input[type="date"], input[type="time"] {
  height: auto;
}

.button {
  margin-bottom: 1rem;
}

textarea {
  box-sizing: border-box;
  height: 3rem;
  padding: .5rem;
  word-wrap: normal;
  font-size: 1rem;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

.page-header {
  padding-bottom: 1rem;
  border-bottom: 3px solid;
  border-color: inherit;
  width: fit-content;
  color: inherit;
}

.btn-inactive {
  background-color: grey;
}

.btn-inactive:hover {
  background-color: grey;
}

.col {
  display: flex;
  flex-direction: column;
}

.cancel {
  background-color: rgba(238, 38, 75, 0.637)
}

.cancel-text {
  background-color: rgba(238, 38, 75, 0.637);
  width: fit-content;
  padding: .5rem;
}


.completed-doc li {
  padding: .5rem;
  color: rgb(0, 76, 0);
}

.incomplete-doc li {
  color: rgb(78, 0, 19);
}

.incomplete-doc li:hover {
  background-color: rgba(240, 248, 255, 0.432);
}

.onboarding-checklist li {
  font-weight: 600;
  margin: .5rem 0;
  padding: .5rem;
  border-radius: 25px;
  width: fit-content;
  transition: all .6s;
}