.text-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 40rem;
  min-width: 35rem;
  height: fit-content;
  color: inherit;
  margin: 1rem 0;
}

@media (max-width: 500px) {
  .text-button {
    flex-direction: column;
    /* align-items: flex-start; */
    min-width: fit-content;
    padding-top: 1rem;
    border-top: 1px solid grey;
  }
}

.text-button-link {
  font-weight: 600;
  display: block;
  background-color: white;
  padding: 1rem;
  text-align: center;
  transition: all .15s;
  border-radius: 50px;
  border: 2px solid grey;
  box-sizing: border-box;
  margin-right: 1rem;
  color: black;
}

.text-button-width {
  width: 30%;
}



.text-button-link:active {
  transform: translateY(3px);
  box-shadow: -.1rem .2rem .2rem gray;;
}

.text-button-link:hover {
  background-color: rgba(154, 83, 185, 0.958);
  color: white;
}

.text-button:hover > .text-button-link{
  background-color: rgba(154, 83, 185, 0.958);
  color: white;
}

.text-button-description {
  width: 70%;
  font-size: .9rem;
  color: inherit;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 50px;
  transition: all .15s;
}

.text-button:hover > .text-button-description {
  background-color: rgba(240, 248, 255, 0.323);
}

@media (max-width: 500px) {
  .text-button-width {
    width: fit-content;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .text-button-description {
    width: auto;
  }
}