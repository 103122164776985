.cbo {
  background-color: beige;
  color: black;
  padding: 1rem;
}

.cbo-report {
  border: 1px solid grey;
  margin: 1rem;
  max-width: 50rem;

}

.cbo-report-title {
  background-color: aqua;
  border-bottom: 1px solid black;
  padding: 1rem;
  margin: 0;
}

.cbo-report-open {
  width: auto;
}

.cbo-dataset {
  padding: 1rem;
  background-color: white;
}


.cbo-date-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.cbo-date-filter-item {
  display: flex;
}
.cbo-date-input-row {
  display: flex;
  align-items: center;
}

.cbo-date-input {
  margin: 1rem;
}

.cbo-month-select {
  margin-left: 1rem;
  margin-top: 15px;
}

.cbo-date-input-section {
  display: flex;
  align-items: center;
  padding: .5rem;
  margin: .5rem 1rem;
}

.cbo-input-selected {
  background-color: rgba(0, 255, 0, 0.54);
}

.cbo-date-range-display {
  background-color: rgba(255, 0, 255, 0.395);
  padding: .5rem 1rem;
  width: fit-content;
  margin-left: 2rem;
}

.cbo-date-bold {
  font-weight: 700;
}